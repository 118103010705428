import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faCookieBite,
    faArrowRotateRight,
    faArrowUpRightFromSquare,
    faCircleCheck,
    faChevronDown,
    faXmark,
    faArrowsRotate,
    faGear,
    faCopy,
    faGift,
    faEllipsisVertical,
    faBars,
    faClockRotateLeft,
    faUser,
    faRightFromBracket,
    faCircleQuestion,
    faEye,
    faBullhorn,
    faClipboardCheck,
    faGrip,
    faMeteor,
    faPlay,
    faCheck,
    faCircleNotch,
    faMobileScreen,
    faDesktop,
    faFire,
    faArrowUpWideShort,
    faArrowDownShortWide,
    faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashCan, faClock } from "@fortawesome/free-regular-svg-icons";
import {
    faFacebookF,
    faXTwitter,
    faInstagram,
    faWhatsapp,
    faPaypal,
    faApple,
    faAndroid,
} from "@fortawesome/free-brands-svg-icons";

library.add(
    faTrashCan,
    faCookieBite,
    faArrowRotateRight,
    faArrowUpRightFromSquare,
    faCircleCheck,
    faChevronDown,
    faXmark,
    faArrowsRotate,
    faGear,
    faCopy,
    faGift,
    faEllipsisVertical,
    faClock,
    faBars,
    faClockRotateLeft,
    faUser,
    faRightFromBracket,
    faCircleQuestion,
    faFacebookF,
    faXTwitter,
    faWhatsapp,
    faEye,
    faBullhorn,
    faClipboardCheck,
    faGrip,
    faMeteor,
    faPlay,
    faCheck,
    faPaypal,
    faCircleNotch,
    faInstagram,
    faApple,
    faAndroid,
    faMobileScreen,
    faDesktop,
    faFire,
    faArrowUpWideShort,
    faArrowDownShortWide,
    faArrowDown
);

export { FontAwesomeIcon };

<script setup>
import { ref, computed } from "vue";
import { Link, usePage, router } from "@inertiajs/vue3";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import axios from "axios";
import ProfilePanel from "@/Components/Navigation/ProfilePanel.vue";
import NavLink from "@/Components/Navigation/NavLink.vue";
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import PointsBalance from "@/Components/Navigation/PointsBalance.vue";
import FlashMessage from "@/Components/FlashMessage.vue";

router.on("navigate", () => {
    sidebarOpen.value = false;
});

defineProps({
    title: {
        type: String,
        default: null,
    },
});

const id = usePage().props.user.id;
const points = computed(() => usePage().props.user.points);

const navigation = [
    {
        name: "Offers",
        route: "offers.index",
        icon: "grip",
        activeOn: [
            "offers.index",
            "offers.web",
            "offers.ios",
            "offers.android",
        ],
    },
    { name: "Explore", route: "explore", icon: "meteor" },
    { name: "Surveys", route: "surveys", icon: "clipboard-check" },
    { name: "Videos", route: "videos", icon: "play" },
    { name: "Invite", route: "invite", icon: "bullhorn" },
    {
        name: "Redeem",
        route: "products.index",
        icon: "gift",
        activeOn: ["products.index", "products.show"],
    },
];

const sidebarOpen = ref(false);

if (id) {
    FingerprintJS.load()
        .then((fp) => fp.get())
        .then((result) => {
            const device = result.visitorId;
            const cookie = getCookie("d");
            if (undefined === cookie || cookie != device) {
                document.cookie = "d=" + device;
                "; expires=" +
                    new Date(Date.now() + 31536000000).toUTCString() +
                    ";path=/";

                axios
                    .put(route("users.device.update"), {
                        device: device,
                    })
                    .catch(function () {
                        // Do nothing...
                    });
            }
        });
}

var oImg;
var cookie = getCookie("hideouttv");
(void 0 !== cookie && cookie == id) ||
    ((oImg = document.createElement("img")),
    oImg.setAttribute(
        "src",
        "https://hideout.tv/cookie.php?id=" + id + "&username=" + id
    ),
    oImg.setAttribute("style", "display:none"),
    document.body.appendChild(oImg),
    (document.cookie =
        "hideouttv=" +
        id +
        "; expires=" +
        new Date(Date.now() + 31536000000).toUTCString() +
        ";path=/"));

function getCookie(e) {
    var o = ("; " + document.cookie).split("; " + e + "=");
    if (2 == o.length) return o.pop().split(";").shift();
}
</script>

<template>
    <div>
        <!-- Desktop sidebar -->
        <aside
            class="z-50 hidden md:fixed md:inset-y-0 md:flex md:w-60 md:flex-col"
        >
            <div class="flex min-h-0 flex-1 flex-col bg-brand">
                <div class="mt-2 flex flex-1 flex-col overflow-y-auto">
                    <div class="flex flex-shrink-0 items-center px-3">
                        <Link
                            :href="route('offers.index')"
                            class="mx-auto mt-1 px-4"
                        >
                            <ApplicationLogo class="w-36" />
                        </Link>
                    </div>

                    <hr class="mt-2 border-brand-400 border-opacity-50" />

                    <PointsBalance :points="points" />

                    <hr class="border-brand-400 border-opacity-50" />

                    <nav class="mt-4 flex-1 space-y-1 px-3">
                        <NavLink
                            v-for="item in navigation"
                            :key="item.route"
                            :name="item.route"
                            :active-on="item.activeOn"
                            class="text-sm"
                        >
                            <font-awesome-icon
                                :icon="['fas', item.icon]"
                                class="mr-3 h-4 w-4 flex-shrink-0 text-brand-200"
                                aria-hidden="true"
                            />
                            {{ item.name }}
                        </NavLink>
                    </nav>
                </div>
                <ProfilePanel />
            </div>
        </aside>

        <div class="flex flex-1 flex-col md:pl-60">
            <!-- Mobile sidebar -->
            <div class="sticky top-0 z-50 bg-brand px-4 py-2 md:hidden">
                <div class="flex items-center">
                    <div class="flex-grow">
                        <Link :href="route('offers.index')">
                            <ApplicationLogo class="w-36" />
                        </Link>
                    </div>
                    <button
                        type="button"
                        class="-mt-0.5 inline-flex items-center justify-center text-brand-50 hover:text-white focus:outline-none"
                        @click="sidebarOpen = !sidebarOpen"
                    >
                        <span class="sr-only">Toggle menu</span>
                        <font-awesome-icon
                            :icon="['fas', sidebarOpen ? 'xmark' : 'bars']"
                            class="h-5 w-5"
                            aria-hidden="true"
                        />
                    </button>
                </div>

                <aside v-if="sidebarOpen" class="bg-brand-500 pb-2 md:hidden">
                    <hr class="mb-4 mt-2 border-brand-400 border-opacity-50" />

                    <PointsBalance :points="points" />

                    <hr class="my-4 border-brand-400 border-opacity-50" />

                    <nav class="mt-3 flex-1 space-y-1">
                        <NavLink
                            v-for="item in navigation"
                            :key="item.route"
                            :name="item.route"
                        >
                            <font-awesome-icon
                                :icon="['fas', item.icon]"
                                class="mr-3 h-4 w-4 flex-shrink-0 text-brand-200"
                                aria-hidden="true"
                            />
                            {{ item.name }}
                        </NavLink>
                    </nav>

                    <hr class="my-2 border-brand-400 border-opacity-50" />

                    <ProfilePanel class="mb-1 mt-2 rounded-lg" />
                </aside>
            </div>

            <main class="z-0 flex-1">
                <div class="py-10">
                    <div class="mx-auto max-w-7xl px-4 md:px-10">
                        <FlashMessage />
                        <slot />
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

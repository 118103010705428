<script setup>
import { computed, ref } from "vue";
import clipboard from "clipboardy";

const props = defineProps({
    link: {
        type: String,
        required: true,
    },
    mini: {
        type: Boolean,
        default: false,
    },
});

const text = ref("Copy");

function copyLink() {
    clipboard.write(props.link);
    text.value = "Copied";
}

const displayLink = computed(() => {
    if (props.mini) {
        return props.link.replace(/^https?:\/\//, "");
    }

    return props.link;
});
</script>

<template>
    <div class="flex rounded-xl shadow-sm">
        <div
            class="w-full overflow-hidden border border-r-0"
            :class="{
                'rounded-l-lg border-brand-500': text === 'Copied' && mini,
                'rounded-l-lg border-gray-300': text !== 'Copied' && mini,
                'rounded-l-xl border-brand-500': text === 'Copied' && !mini,
                'rounded-l-xl border-gray-300': text !== 'Copied' && !mini,
            }"
        >
            <input
                :value="displayLink"
                readonly
                class="w-full flex-grow cursor-pointer border-none text-gray-900 outline-none"
                :class="{
                    'p-2 text-xs': mini,
                    'p-3.5': !mini,
                }"
                @click="copyLink"
            />
        </div>

        <button
            class="bg-brand-500 text-center font-display font-medium text-white hover:bg-brand-600 group-hover:bg-brand-600"
            :class="{
                'w-24 rounded-r-lg text-xs': mini,
                'w-40 rounded-r-xl': !mini,
            }"
            @click="copyLink"
        >
            <font-awesome-icon
                v-if="!mini"
                :icon="['fas', 'copy']"
                class="-mt-0.5 mr-2.5 inline h-4 w-4"
            />
            <span class="inline">{{ text }}</span>
        </button>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { router } from "@inertiajs/vue3";
import debounce from "lodash/debounce";

defineProps({
    points: {
        type: String,
        required: true,
    },
});
const loading = ref(false);

const updateUser = debounce(
    () => {
        router.reload({
            only: ["user"],
            onSuccess: () => {
                loading.value = false;
            },
        });
    },
    500,
    { leading: true }
);

function refresh() {
    loading.value = true;
    updateUser();
}
</script>

<template>
    <button
        class="w-full cursor-pointer rounded-full bg-brand-800 p-2.5 text-center font-display text-lg font-bold text-white md:mx-3 md:my-4 md:w-auto md:text-sm"
        @click="refresh"
    >
        <div v-if="loading">
            <font-awesome-icon
                :icon="['fas', 'arrows-rotate']"
                class="m-auto h-4 animate-spin"
                aria-hidden="true"
            />
        </div>
        <div v-else>
            {{ Math.floor(points).toLocaleString()
            }}<span class="ml-1 text-xs font-semibold text-brand-50"
                >Point{{ Math.floor(points) != 1 ? "s" : "" }}</span
            >
        </div>
        <span class="sr-only">Refresh points</span>
    </button>
</template>

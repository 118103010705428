import "vanilla-cookieconsent/dist/cookieconsent.css";
import "vanilla-cookieconsent/dist/cookieconsent";

window.cookieConsent = window.initCookieConsent();

window.addEventListener("load", function () {
    if (!window.Cypress) {
        window.cookieConsent.run({
            current_lang: "en",
            page_scripts: true,
            autoclear_cookies: true,
            hide_from_bots: true,
            revision: 1,

            gui_options: {
                consent_modal: {
                    layout: "cloud",
                    position: "bottom center",
                    transition: "zoom",
                    swap_buttons: true,
                },
                settings_modal: {
                    layout: "box",
                    transition: "zoom",
                },
            },

            languages: {
                en: {
                    consent_modal: {
                        title: "🍪 We use cookies!",
                        description:
                            'This website uses essential cookies to ensure its proper operation and analytics cookies to understand how you interact with it, that will be set only after you consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                        primary_btn: {
                            text: "Accept All",
                            role: "accept_all",
                        },
                        secondary_btn: {
                            text: "Reject All",
                            role: "accept_necessary",
                        },
                    },
                    settings_modal: {
                        title: "Cookie Preferences",
                        save_settings_btn: "Save Settings",
                        accept_all_btn: "Accept All",
                        reject_all_btn: "Reject All",
                        close_btn_label: "Close",
                        cookie_table_headers: [
                            { col1: "Name" },
                            { col2: "Source" },
                            { col3: "Description" },
                        ],
                        blocks: [
                            {
                                title: "🍪 Cookie Usage",
                                description:
                                    'Cookies are used to ensure the basic functionalities of the website and to enhance your online experience. You can choose to opt-in to or opt-out of each category. For more details on cookies and your personal data, please read the full <a href="/privacy" class="cc-link">Privacy Policy</a>.',
                            },
                            {
                                title: "Strictly Necessary Cookies",
                                description:
                                    "These cookies are essential for the proper operation of this website.",
                                toggle: {
                                    value: "necessary",
                                    enabled: true,
                                    readonly: true,
                                },
                            },
                            {
                                title: "Analytics Cookies",
                                description:
                                    "These cookies collect information about how you use the website, which pages you visited and which links you clicked on.",
                                toggle: {
                                    value: "analytics",
                                    enabled: false,
                                    readonly: false,
                                },
                            },
                        ],
                    },
                },
            },
        });
    }
});

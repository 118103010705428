<script setup>
import { computed, ref } from "vue";
import { usePage } from "@inertiajs/vue3";

const message = computed(() => usePage().props.flash.message);
const show = ref(true);

const icon = computed(() => {
    switch (message.value.type) {
        case "error":
            return ["fas", "xmark"];
        default:
            return ["fas", "check"];
    }
});
</script>

<template>
    <Transition
        enter-active-class="ease-in-out duration-500"
        enter-from-class="opacity-0 translate-y-4"
        enter-to-class="opacity-100 translate-y-0"
        appear
    >
        <div
            v-if="show && message"
            class="relative mb-10 flex items-center space-x-5 rounded-xl border-2 p-5 md:pl-7"
            :class="{
                'border-green-300 bg-green-100 text-green-900':
                    message.type == 'success',
                'border-red-300 bg-red-100 text-red-900':
                    message.type == 'error',
            }"
        >
            <div
                class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full"
                :class="{
                    'bg-green-500': message.type == 'success',
                    'bg-red-500': message.type == 'error',
                }"
            >
                <font-awesome-icon :icon="icon" class="h-5 w-5 text-white" />
            </div>
            <div>
                <h4 class="mb-1 flex-grow font-display font-medium">
                    {{ message.title }}
                </h4>

                <p
                    class="text-sm leading-relaxed opacity-75"
                    v-html="message.text"
                />
            </div>

            <button
                class="group absolute right-5 top-5 rounded-full p-1"
                @click="show = false"
            >
                <font-awesome-icon
                    :icon="['fas', 'xmark']"
                    class="h-5 w-5 opacity-50 group-hover:opacity-100"
                />
                <span class="sr-only">Close</span>
            </button>
        </div>
    </Transition>
</template>

<template>
    <div
        class="mx-auto flex min-h-screen w-full max-w-7xl flex-col px-4 pb-16 pt-16 md:px-10 md:pt-32"
    >
        <div
            class="prose max-w-max text-sm leading-loose prose-headings:mb-5 prose-headings:text-left prose-headings:font-display prose-headings:tracking-tight prose-headings:text-brand-900 prose-h1:mb-12 prose-h1:text-4xl prose-h1:font-black prose-h1:leading-tight prose-h2:mb-10 prose-h2:text-3xl prose-h2:font-bold prose-h2:leading-tight prose-h3:text-2xl prose-h3:font-bold prose-h4:font-display prose-h4:text-lg prose-h4:font-semibold prose-li:marker:text-brand-900 prose-table:table-fixed prose-table:overflow-hidden prose-table:rounded-xl prose-table:border prose-table:border-gray-200 prose-table:text-sm prose-table:shadow-sm prose-table:ring-1 prose-table:ring-black prose-table:ring-opacity-5 odd:prose-tr:bg-gray-50 even:prose-tr:bg-white prose-th:px-6 prose-th:py-4 prose-th:text-center prose-th:font-sans prose-th:font-semibold prose-th:tracking-normal prose-td:px-6 prose-td:py-4 prose-td:leading-loose sm:justify-center md:text-base md:leading-loose prose-h1:md:text-6xl prose-p:md:leading-loose prose-table:md:text-base"
        >
            <slot />
        </div>
    </div>
</template>

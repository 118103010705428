<script setup>
import { computed } from "vue";
import { Link, usePage } from "@inertiajs/vue3";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import ReferralLink from "../Referrals/ReferralLink.vue";
import LazyImage from "../LazyImage.vue";

const id = usePage().props.user.id;
const name = usePage().props.user.name;
const email = usePage().props.user.email;
const fallback = `https://unavatar.io/${email}`;
const avatar = usePage().props.user.avatar ?? fallback;

const navigation = [
    {
        name: "Activity",
        route: "transactions.index",
        icon: "clock-rotate-left",
    },
    { name: "Rewards", route: "redemptions.index", icon: "gift" },
    { name: "Settings", route: "settings", icon: "gear" },
];

const displayName = computed(() => {
    if (name && name.length > 16 && name.includes(" ")) {
        return name.split(" ")[0];
    }

    return name || email;
});
</script>

<template>
    <Menu v-slot="{ open }" as="div" class="relative mb-3 mt-4 flex md:mx-3">
        <MenuButton
            class="group flex w-full min-w-0 items-center p-2.5 text-left"
            :class="
                open
                    ? 'text-white'
                    : 'flex-inline text-brand-50 hover:text-white'
            "
        >
            <LazyImage
                image-class="h-10 w-10 rounded-full"
                class="rounded-full border-2 border-white bg-gray-100"
                :error="fallback"
                :src="avatar"
                alt=""
            />
            <div class="ml-3 flex-1 overflow-auto">
                <p
                    class="truncate font-display font-semibold tracking-tight text-white"
                    :class="
                        displayName.length > 14 ? 'md:text-xs' : 'md:text-sm'
                    "
                >
                    {{ displayName }}
                </p>
                <p
                    class="mt-0.5 text-sm font-medium md:text-xs"
                    :class="
                        open
                            ? 'text-brand-50'
                            : 'text-brand-200 group-hover:text-brand-50'
                    "
                >
                    Your Account
                </p>
            </div>
            <font-awesome-icon :icon="['fas', 'gear']" class="ml-2.5 h-4 w-4" />
            <span class="sr-only">Open Sub-Menu</span>
        </MenuButton>

        <div
            class="absolute bottom-16 right-0 w-56 md:bottom-0 md:right-auto md:ml-60 md:w-[15.5rem]"
        >
            <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
            >
                <MenuItems
                    v-show="open"
                    class="rounded-xl bg-white p-2 shadow-sm ring-1 ring-black ring-opacity-5"
                >
                    <MenuItem
                        v-for="item in navigation"
                        :key="item.route"
                        v-slot="{ active, close }"
                    >
                        <Link
                            :class="[
                                active ? 'bg-gray-100 shadow-sm' : '',
                                'group flex items-center rounded-lg px-3 py-2.5 text-sm text-gray-900',
                            ]"
                            :href="route(item.route)"
                            @success="close"
                        >
                            <font-awesome-icon
                                :icon="['fas', item.icon]"
                                :class="[
                                    active ? 'text-gray-900' : 'text-gray-500',
                                    'mr-3 h-4 w-4 flex-shrink-0',
                                ]"
                                aria-hidden="true"
                            />
                            {{ item.name }}
                        </Link>
                    </MenuItem>

                    <hr class="my-2 border-gray-100" />

                    <MenuItem v-slot="{ active }">
                        <a
                            :class="[
                                active ? 'bg-gray-100 shadow-sm' : '',
                                'group flex w-full items-center rounded-lg px-3 py-2.5 text-sm text-gray-900',
                            ]"
                            href="https://help.earnably.com"
                            rel="noopener noreferrer"
                            target="_blank"
                            as="button"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'circle-question']"
                                :class="[
                                    active ? 'text-gray-900' : 'text-gray-500',
                                    'mr-3 h-4 w-4 flex-shrink-0',
                                ]"
                                aria-hidden="true"
                            />
                            <div class="inline text-left">
                                Get Help
                                <span
                                    class="mt-0.5 block text-xs text-gray-500 group-hover:text-gray-700"
                                    >Go to Help Center &rarr;</span
                                >
                            </div>
                        </a>
                    </MenuItem>

                    <hr class="my-2 border-gray-100 md:hidden" />

                    <div
                        class="-mx-2 my-2 hidden border-y border-gray-100 bg-gray-50 px-[1.25rem] py-5 md:inline-block"
                    >
                        <h4
                            class="mb-2 font-display text-sm font-semibold text-brand-900"
                        >
                            Refer & Earn
                        </h4>
                        <p class="mb-3 text-xs leading-relaxed text-gray-600">
                            Share your referral link with friends to earn
                            <strong class="font-display">10%</strong> of their
                            earnings for life.
                        </p>
                        <ReferralLink
                            mini
                            :link="route('join', { code: id })"
                        />
                    </div>

                    <MenuItem v-slot="{ active }">
                        <Link
                            :class="[
                                active ? 'bg-gray-100 shadow-sm' : '',
                                'group flex w-full items-center rounded-lg px-3 py-2.5 text-sm text-gray-900',
                            ]"
                            :href="route('signout')"
                            method="post"
                            as="button"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'right-from-bracket']"
                                :class="[
                                    active ? 'text-gray-900' : 'text-gray-500',
                                    'mr-3 h-4 w-4 flex-shrink-0',
                                ]"
                                aria-hidden="true"
                            />
                            <div class="inline truncate text-left">
                                Sign Out
                                <div
                                    class="mt-0.5 block text-xs text-gray-500 group-hover:text-gray-700"
                                >
                                    {{ email }}
                                </div>
                            </div>
                        </Link>
                    </MenuItem>
                </MenuItems>
            </transition>
        </div>
    </Menu>
</template>

/**
 * Adapted from https://github.com/inertiajs/progress/blob/master/src/progress.js
 */

import NProgress from "nprogress";

let timeout = null;

function start(delay) {
    timeout = setTimeout(() => NProgress.start(), delay);
}

function progress(event) {
    if (NProgress.isStarted() && event.detail.progress.percentage) {
        NProgress.set(
            Math.max(
                NProgress.status,
                (event.detail.progress.percentage / 100) * 0.9
            )
        );
    }
}

function finish(event) {
    clearTimeout(timeout);

    if (!NProgress.isStarted()) {
        return;
    }

    if (!event) {
        NProgress.done();
        return;
    }

    if (event.detail.visit.completed) {
        NProgress.done();
    } else if (event.detail.visit.interrupted) {
        NProgress.set(0);
    } else if (event.detail.visit.cancelled) {
        NProgress.done();
        NProgress.remove();
    }
}

function init(delay = 250) {
    document.addEventListener("inertia:start", start.bind(null, delay));
    document.addEventListener("inertia:progress", progress);
    document.addEventListener("inertia:finish", finish);

    NProgress.configure({ showSpinner: false });
}

export default { start, finish, init };

import "./bootstrap";
import "./cookies";
import "../css/app.css";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import VueLazyload from "vue-lazyload";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";
import { FontAwesomeIcon } from "@/icons";
import progress from "@/progress";
import Authenticated from "@/Layouts/Authenticated.vue";
import Guest from "@/Layouts/Guest.vue";

const appName = "Earnably";

createInertiaApp({
    progress: false,
    title: (title) => `${title} | ${appName}`,
    resolve: (name) => {
        const page = resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        );
        page.then((module) => {
            // The Home component doesn't need a layout.
            if (name === "Guest/Home") {
                return;
            }

            if (name.includes("Guest")) {
                module.default.layout = Guest;
            } else {
                // The Authenticated layout is the default layout.
                module.default.layout = Authenticated;
            }
        });
        return page;
    },
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(VueLazyload)
            .use(ZiggyVue)
            .component("font-awesome-icon", FontAwesomeIcon)
            .component(VueQrcode.name, VueQrcode)
            .mount(el);
    },
});

progress.init();
